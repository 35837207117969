<template>
  <div>
    <v-btn
      fixed
      fab
      bottom
      right
      color="success"
      :to="{ name: 'AttendanceChecksSpecialAdd', params: { id: 0 } }"
      ><v-icon>mdi-plus</v-icon></v-btn
    >
    <v-card class="mt-4" v-if="$vuetify.breakpoint.xs" :loading="loading">
      <v-system-bar>Abwesenheiten bei Sonderveranstaltungen</v-system-bar>
      <v-list>
        <v-list-item v-for="item in items" :key="item.id">
          <v-list-item-avatar
            ><PortraitImage :value="item.student"
          /></v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ item.student.name }}</v-list-item-title>
            <v-list-item-subtitle
              ><DateValue :value="item.date" />, {{ item.startTime }} –
              {{ item.endTime }}</v-list-item-subtitle
            >
            <v-list-item-subtitle
              ><strong>{{ item.description }}</strong></v-list-item-subtitle
            >
          </v-list-item-content>
          <v-list-item-action @click="trash(item)"
            ><v-icon>mdi-trash-can</v-icon></v-list-item-action
          >
        </v-list-item>
      </v-list>
    </v-card>
    <v-card class="mt-4" v-else :loading="loading">
      <v-system-bar>Abwesenheiten bei Sonderveranstaltungen</v-system-bar>
      <v-data-table :items="items" :headers="headers">
        <template v-slot:item.student="{ item }">
          <PersonItem small :value="item.student" />
        </template>
        <template v-slot:item.date="{ item }">
          <DateValue :value="item.date" />
        </template>
        <template v-slot:item.startTime="{ item }">
          {{ item.startTime }} –
          {{ item.endTime }}
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn icon @click="trash(item)"
            ><v-icon>mdi-trash-can</v-icon></v-btn
          >
        </template>
      </v-data-table>
    </v-card>

    <router-view></router-view>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import DateValue from "common/components/DateValue.vue";
import PersonItem from "common/components/PersonItem.vue";
import PortraitImage from "common/components/PortraitImage.vue";

import { comparePeople, personName } from "common/utils/people.js";
export default defineComponent({
  components: { DateValue, PersonItem, PortraitImage },
  data() {
    return {
      items: [],
      loading: false,
      headers: [
        {
          text: "Datum",
          value: "date",
        },
        {
          text: "Lektion",
          value: "startTime",
        },
        {
          text: "Schüler*in",
          value: "student",
          sort: comparePeople,
        },
        {
          text: "Beschreibung",
          value: "description",
        },
        {
          text: "Status",
          value: "status.description",
        },
        {
          text: "",
          value: "action",
        },
      ],
    };
  },
  methods: {
    async fetchData() {
      this.loading = true;
      const checks = await this.apiList({
        resource: "absence/absence",
        query: "special",
      });
      this.loading = false;
      this.items = checks.sort((a, b) => this.compareItems(a, b));
    },
    compareItems(a, b) {
      if (a.date > b.date) return -1;
      if (a.date < b.date) return 1;
      if (a.startTime > b.startTime) return -1;
      if (a.startTime < b.startTime) return 1;
      return 0;
    },
    async trash(item) {
      if (
        await this.$root.confirm({
          message:
            "Soll die Abwesenheit für " +
            personName(item.student) +
            " gelöscht werden?",
          color: "danger",
          icon: "mdi-trash-can",
        })
      ) {
        await this.apiDelete({ resource: "absence/absence", id: item.id });
        this.fetchData();
      }
    },
  },
  created() {
    this.fetchData();
  },
  async beforeRouteUpdate(to, from, next) {
    if (from.name === "AttendanceChecksSpecialAdd") {
      await this.fetchData();
    }
    next();
  },
});
</script>
